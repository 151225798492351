export const MonthData = [
    {
        id: 0,
        month: "Select Month"
    },
    {
        id: 1,
        month: "Jan"
    },
    {
        id: 2,
        month: "Feb"
    },
    {
        id: 3,
        month: "March"
    },
    {
        id: 4,
        month: "April"
    },
    {
        id: 5,
        month: "May"
    },
    {
        id: 6,
        month: "June"
    },
    {
        id: 7,
        month: "July"
    },
    {
        id: 8,
        month: "Aug"
    },
    {
        id: 9,
        month: "Sept"
    },
    {
        id: 10,
        month: "Oct"
    },
    {
        id: 11,
        month: "Nov"
    },
    {
        id: 12,
        month: "Dec"
    },
]